import React, { FC } from 'react';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import ActionEditorBase from '../ActionEditorBase';
import ShowInResourceView from '../ShowInResourceView';

const LinkResourceEditor: FC<EditorProps> = (props) => {
  return (
    <div data-cy="link-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <ShowInResourceView {...props} />
    </div>
  );
};

export default LinkResourceEditor;
